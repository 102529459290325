
import api from '@/plugins/api'
import { openUrl } from '~/plugins/util'
import { mapState, mapMutations } from 'vuex'
import Banner from '~/components/Banner'
import Title from '~/components/Title'

export default {
  name: 'index',
  components: { Banner, Title },
  computed: {
    ...mapState('home', ['mainPlate', 'hotList', 'historyList', 'today']),
    hotSwiper () {
      return this.$refs.hotSwiper.$swiper
    },
  },
  head() {
    return {
      title: '初心地图',
      meta: [{
        hid: "index page",
        name: "description",
        content: "赣州市智能产业创新研究院"
      }]
    }
  },
  data() {
    return {
      currentPlate: -1,
      currentHot: 0,
      hotType: [
        { text: '热门景点', api: 'hotNews', type: 1 },
        { text: '热门故事', api: 'hotEvent', type: 2 },
        { text: '历史热点人物', api: 'hotFigure', type: 3 }
      ],
      hotOption: {
        initialSlide: 0,
        slidesPerView: 1,
        loop: true,
        autoplay: {
            disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
            delay: 5000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      isLoading: false
    };
  },
  watch: {
  },
  async fetch({ store, $axios }) {
    let banner = await $axios.$post(api.getBanner, {code: 'home '});
    store.commit('setBannerList', banner.info || []);

    let result = await $axios.$post(api.hotNews, { page: 1, page_size: 5 });
    store.commit('home/setHotList', result.list || []);

    let history = await $axios.$post(api.getHistoryToday, { page: 1, page_size: 5 });
    store.commit('home/setTodayList', history)
  },
  mounted() {
  },
  methods: {
    ...mapMutations('home', ['setHotList']),

    formatDay(date) {
      if(!date) return '';
      try{
        if(date.indexOf('年')) {
          date = date.split('年')[1];
          if(date.indexOf('10') == -1) date = date.replace(/0/g, '');
        }
      } catch{}
      return date;
    },

    getHeight(i) {
      let refs = this.$refs.historyText
      if(!(refs instanceof Array)) return;

      if(refs[i].innerHTML.length > 230) {
        let content = refs[i].innerHTML;
        content = content.substr(0, 230);
        // 最后一个字替换成...
        refs[i].innerHTML = content.replace(/.(\.\.\.)?$/,"...");
      }
    },

    mouseover(i) {
      if(this.currentPlate == i) return;
      this.currentPlate = i;
    },

    changeHot(i) {
      if(this.currentHot == i) return;
      this.currentHot = i;
      this.getHotList();
    },

    getHotList() {
      if(this.isLoading) return;
      this.isLoading = true;
      this.$axios.$post(api[this.hotType[this.currentHot].api], { 
        page: 1,
        page_size: 5
       }).then(result => {
        this.isLoading = false;
        this.setHotList(result.list || [])
        this.hotSwiper.slideTo(1, 1000, true);
      })
      .catch(err => {
        this.isLoading = false;
        this.$message.warning(err)
      });
    },

    toPlate(item) {
      if(item.url)  return window.open(item.url, "_blank");

      this.$router.push({
        path: item.path,
        query: item.query
      })
    },

    toDetail({item, name, type}) {
      openUrl(this.$router, {
        path: "/map/detail",
        query: {
          name: name || item.name || item.title,
          type: type || this.hotType[this.currentHot].type,
        }
      })
    }

  },
  beforeDestroy() {
    
  }
};
