
export default {
    props: {
        text: { type: String, default: '' },
        eng: { type: String, default: '' }
    },
    data() {
        return {
            height: 800
        };
    },
    computed: {
    },
    created() {
    },
    methods: {

    }
};
