import { render, staticRenderFns } from "./index.vue?vue&type=template&id=10282c3d&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=10282c3d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10282c3d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/www/wwwroot/red_open_palm_3.0/components/Banner.vue').default,Title: require('/www/wwwroot/red_open_palm_3.0/components/Title.vue').default})
