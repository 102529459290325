
import { mapState } from 'vuex'
export default {
  name: 'Banner',
  props:{
    isHome:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      bannerList: state => state.bannerList
    })
  },
  data() {
    return{
      bannerOption: {
        initialSlide: 0,
        slidesPerView: 1,
        loop: true,
        autoplay:true,
        pagination: {
          el: '.swiper-pagination',
          clickable:true
        }
      },
    }
  },
  methods: {
    judgePic(url) {
      if(!url) return '';
      try{
        if(url.indexOf('://') != -1) return url;
      }catch {
        return this.$store.state.domain + url;
      }
      return this.$store.state.domain + url;
    },
  }
}
